<template>
    <a @click.prevent="showAuthPopup" class="header__button main-button main-button--turquoise" type="button">
        Авторизация
    </a>
</template>

<script>
    export default {
        name:       'AuthButton',
        methods:    {
            showAuthPopup() {
                import(/* webpackChunkName: "Auth.Popup" */ 'entries/auth/Popup').then(component => {
                    this.$modal.show(component.default, {}, {classes: 'vmodalstyled registration-popup', height: 'auto'});
                });
            },
        },
    };
</script>
