export const ROUTE_ID_ACTIVE_ALARMS  = 'active_alarms';
export const ROUTE_ID_HISTORY_ALARMS = 'history_alarms';

export const ROUTE_URL_ACTIVE_ALARMS  = '/office/alarmcalls/new/';
export const ROUTE_URL_HISTORY_ALARMS = '/office/alarmcalls/history/';

const routes = [
    {
        name:      ROUTE_ID_ACTIVE_ALARMS,
        path:      ROUTE_URL_ACTIVE_ALARMS,
    },
    {
        name:      ROUTE_ID_HISTORY_ALARMS,
        path:      ROUTE_URL_HISTORY_ALARMS,
    }
];

export const routerCommon = new VueRouter({
    mode: 'history',
    routes
});