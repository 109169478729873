import {routerCommon} from 'router';

export default function (box_id, component, props = {}) {
    if (document.getElementById(box_id)) {
        const vm = new Vue({
            store: window.store_global || new Vuex.Store({}),
            router: routerCommon,
            render(h) {
                return h(component, {props: props});
            },
        });
        vm.$mount('#' + box_id);
    }
}